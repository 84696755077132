import refsCommon from "./refsCommon";
// import JustValidate from 'just-validate';

const { form } = refsCommon;

document.addEventListener("DOMContentLoaded", async () => {
	if (!form) return;
	const { default: JustValidate } = await import("just-validate");
	const validate = new JustValidate("#form");

	validate
		.addField("#form-name", [
			{
				rule: "minLength",
				value: 2,
			},
			{
				rule: "required",
			},
		])
		.addField("#form-lastname", [
			{
				rule: "minLength",
				value: 2,
			},
			{
				rule: "required",
			},
		])
		.addField("#form-phone", [
			{
				rule: "required",
			},
			{
				rule: "minLength",
				value: 9,
			},
		])
		.addField("#form-email", [
			{
				rule: "required",
			},
			{
				rule: "email",
				errorMessage: "Email required",
			},
		])

		.onSuccess((e) => {
			console.log("Validation passed!");
			// e.preventDefault();

			const formData = new FormData(form);
			for (let [name, value] of formData.entries()) {
				console.log(`${name}: ${value}`);
			}
			form.reset();
			// window.location.href = "success.html";
			// fetch('/submit', {
			//   method: 'POST',
			//   body: formData,
			// });
		});
});
